<template>
    <div class="onboarding-container">
        <v-stepper alt-labels flat v-model="stepIndex">
            <div style="display: flex; justify-content: space-between; flex-wrap: wrap">
                <div class="text-center" style="width: 100%" v-if="!tributeVideo.mainPhotoId">
                    <h1 style="font-size: 36px; font-weight: 800" class="text-heading text-center">Tribute Video</h1>
                </div>
                <div class="mr-12" v-else>
                    <div style="display: flex; align-items: center">
                        <v-avatar class="mr-2" v-if="mainPhotoUrl" size="70">
                            <v-img
                                :src="handleMainImgSrc(mainPhotoUrl)"
                                :lazy-src="handleMainImgSrc(mainPhotoUrl)"
                                alt="Avatar"
                            />
                        </v-avatar>
                        <div style="display: flex; flex-direction: column; justify-content: center">
                            <h3>Tribute Video</h3>
                            <p @click="stepIndex = 1" class="m-0 pointer deadline-link">
                                <font-awesome-icon icon="fa-regular fa-calendar-circle-exclamation" />
                                <span v-if="tributeVideo.deadline">
                                    {{ $moment(tributeVideo.deadline).format('MMM Do, h:mm a') }}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <TributeOnboardingStepper
                    @step-click="val => handleStepClick(val)"
                    v-show="windowIndex > 0"
                    :stepIndex="stepIndex"
                />
            </div>

            <v-divider style="border-color: #eeeeee" />

            <v-window v-model="windowIndex">
                <v-window-item>
                    <div
                        class="text-center p-1"
                        style="
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 32px !important;
                        "
                    >
                        <h3 style="font-weight: 800; font-size: 20px; margin-bottom: 16px" class="text-heading">
                            Welcome to MemoryShare's Tribute Video Creator
                        </h3>
                        <p
                            style="max-width: 600px; font-size: 16px; line-height: 22px; margin-bottom: 32px"
                            class="text-body-light"
                        >
                            MemoryShare uses AI to create Tribute Videos. Download our mobile app to scan photos and
                            collect stories from family members. Once the photos are collected, choose a template,
                            choose the music, and the video will be created. MemoryShare is different from other tribute
                            video creators because we encourage family members to share their memories of
                            {{ service.firstName + ' ' + service.lastName }}.
                        </p>

                        <button @click="initOnboard" class="btn-blue">Let's Get Started</button>
                    </div>
                </v-window-item>
                <v-window-item eager>
                    <v-stepper-items>
                        <v-stepper-content :step="1">
                            <div class="text-center d-flex flex-column align-items-center p-3">
                                <h3>Let's set a Deadline</h3>
                                <p>
                                    Setting a deadline for uploads will inform contributors when to submit uploads to be
                                    included in the rendered tribute video.
                                </p>
                                <div class="calendar-wrapper px-6">
                                    <calendar
                                        class="calender-component"
                                        ref="deadlineCalendar"
                                        inline
                                        disable-past-dates
                                        datetime
                                        v-model="tributeDeadline"
                                        label="Deadline"
                                        :output-local="true"
                                        hide-save-buttons
                                        @inline-save="handleInlineSave"
                                        @show-time="val => (calendarShowingTime = val)"
                                    />
                                </div>

                                <div style="width: 320px" class="d-flex flex-row justify-space-between">
                                    <v-btn
                                        v-if="tributeVideo.deadline"
                                        @click="clearDeadline"
                                        depressed
                                        class="mr-2"
                                        style="width: 40%"
                                    >
                                        <span> Clear </span>
                                        <font-awesome-icon
                                            style="font-size: 0.9rem"
                                            class="ml-2 mb-1"
                                            icon="fa-regular fa-broom-wide"
                                        ></font-awesome-icon>
                                    </v-btn>
                                    <v-btn v-else @click="skipDeadline" depressed class="mr-2" style="width: 40%"
                                        >Skip</v-btn
                                    >

                                    <v-btn
                                        v-if="!calendarShowingTime"
                                        @click="handleSetDeadlineDate"
                                        depressed
                                        outlined
                                        class="deadline-btn"
                                    >
                                        <span> Set Deadline </span>
                                        <font-awesome-icon
                                            style="font-size: 0.9rem"
                                            class="ml-2 mb-1"
                                            icon="fa-regular fa-calendar-day"
                                        ></font-awesome-icon>
                                    </v-btn>

                                    <v-btn v-else @click="handleDeadlineSave" depressed outlined class="deadline-btn">
                                        <span> Set Time </span>
                                        <font-awesome-icon
                                            style="font-size: 0.9rem"
                                            class="ml-2 mb-1"
                                            icon="fa-regular fa-clock"
                                        ></font-awesome-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content :step="2">
                            <div class="text-center p-3">
                                <h3 style="font-weight: 800; font-size: 32px; margin-bottom: 40px" class="text-heading">
                                    Let's First Identify {{ service.firstName }}.
                                </h3>
                                <div v-if="confirmingPhoto">
                                    <v-avatar class="m-3" size="300">
                                        <v-img
                                            v-if="confirmationPhotoUrl"
                                            :src="handleMainImgSrc(confirmationPhotoUrl)"
                                            :lazy-src="handleMainImgSrc(confirmationPhotoUrl)"
                                        ></v-img>
                                    </v-avatar>
                                    <p class="mt-3 text-heading" style="font-size: 16px; font-weight: 500">
                                        Does this look like {{ service.firstName }}?
                                    </p>

                                    <v-progress-linear v-if="loading" indeterminate rounded class="mt-2">
                                    </v-progress-linear>
                                    <div style="display: flex; justify-content: center; margin-top: 20px; gap: 8px">
                                        <button class="btn-ghost" :disabled="loading" @click="chooseAnotherMainPhoto">
                                            Choose Another Photo
                                        </button>

                                        <button class="btn-orange" :disabled="loading" @click="handleMainPhotoConfirm">
                                            Yes, Looks Good
                                        </button>
                                    </div>
                                </div>

                                <!-- START: Uploader -->
                                <div
                                    :class="confirmingPhoto ? 'hidden' : ''"
                                    style="max-width: 600px; max-height: 300px; margin: 0 auto"
                                >
                                    <photo-uploader-v2
                                        class="main-photo-uploader"
                                        ref="mainPhotoUploader"
                                        :uploaderId="'mainPhotoUploader'"
                                        :max="1"
                                        :requiresConfirm="true"
                                        :videoAllowed="false"
                                        :eventId="event.id"
                                        :customCta="true"
                                        @all-uploads-complete="handleConfirmMainFinished"
                                        @confirm="photo => promptMainPhotoConfirm(photo)"
                                        @upload-cancelled="handleUploadCancelled('mainPhotoUploader')"
                                    >
                                        <template v-slot>
                                            <div
                                                @click="handleUppyOpen('mainPhotoUploader')"
                                                class="d-flex flex-column p-3 main-photo-cta"
                                            >
                                                <font-awesome-icon
                                                    stroke="#7A8187"
                                                    style="font-size: 4rem; margin-bottom: 8px"
                                                    icon="fa-regular fa-cloud-arrow-up"
                                                />
                                                <p
                                                    class="text-center text-body-light"
                                                    style="font-weight: 500; font-size: 14px"
                                                >
                                                    <span style="color: #2096f3">Click</span> or Drag and Drop Photo
                                                </p>
                                            </div>
                                        </template></photo-uploader-v2
                                    >
                                    <!-- END: Uploader -->
                                </div>
                                <p
                                    v-if="!confirmingPhoto"
                                    class="text-heading"
                                    style="max-width: 300px; margin: 40px auto; font-weight: 500; font-size: 16px"
                                >
                                    Upload a photo of {{ service.firstName }} as refrence for our AI to work their
                                    magic.
                                </p>
                                <v-btn
                                    class="mt-2"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="skipMainPhotoUpload(event.id)"
                                    depressed
                                    >Skip
                                    <font-awesome-icon class="ml-2" icon="fa-regular fa-arrow-right" />
                                </v-btn>
                            </div>
                        </v-stepper-content>

                        <v-stepper-content class="text-center" :step="3">
                            <ShareTab
                                :min-photos="minPhotos"
                                :tribute-video="tributeVideo"
                                :processing-uploads="processingUploads"
                                :event="event"
                                :service="service"
                                display-onboard-override
                                @upload-start="files => $emit('upload-start', files)"
                                @upload-progress="progress => $emit('upload-progress', progress)"
                                @all-uploads-complete="$emit('all-uploads-complete')"
                                @init-share-modal="initShareModal"
                                @onboard-override="$emit('onboard-override')"
                                @upload-success="remaining => this.$emit('upload-success', remaining)"
                                @progress="progress => this.$emit('upload-progress', progress)"
                            />
                        </v-stepper-content>
                    </v-stepper-items>
                </v-window-item>
            </v-window>
        </v-stepper>

        <!-- START: Invite Modal -->
        <share-upload-modal
            v-if="tributeVideo.deeplink"
            ref="shareModal"
            :deeplink="tributeVideo.deeplink"
            :tributeVideo="tributeVideo"
            :service="service"
            @submit-invites="contacts => handleInviteSubmit(contacts)"
            @invalidEmail="email => handleInvalidEmail(email)"
        ></share-upload-modal>
        <!-- END: Invite Modal -->
    </div>
</template>
<script>
import PhotoUploaderV2 from '@/components/ManageService/Tribute/PhotoUploaderV2.vue';
import EmailInvite from '@/components/ui/EmailInvite.vue';
import ShareUploadModal from '@/components/ManageService/Tribute/ShareUploadModal.vue';
import Calendar from '@/components/ui/Calendar.vue';
import { mapActions } from 'vuex';
import TributeStoryUploader from '@/components/ManageService/Tribute/TributeStoryUploader.vue';
import ShareTab from '@/components/ManageService/Tribute/Tabs/ShareTab.vue';
import TributeOnboardingStepper from '@/components/ManageService/Tribute/TributeOnboardingStepper.vue';

export default {
    data() {
        return {
            confirmationPhotoUrl: null,
            confirmingPhoto: false,
            windowIndex: 0,
            stepIndex: 1,
            e1: 1,
            stepHeaders: [
                { text: 'Overview', value: 1 },
                { text: 'Identify', value: 2 },
                { text: 'Upload Photos', value: 3 },
            ],
            emailList: [],
            shareUploadModal: false,
            formValid: false,
            token: '',
            tributeDeadline: '',
            setTributeDeadline: false,
            loading: false,
            calendarShowingTime: false,
            steps: [
                { label: 'Overview', value: 1 },
                { label: 'Identify', value: 2 },
                { label: 'Upload Photos', value: 3 },
            ],
        };
    },
    components: {
        EmailInvite,
        ShareUploadModal,
        Calendar,
        TributeStoryUploader,
        ShareTab,
        PhotoUploaderV2,
        TributeOnboardingStepper,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
        serviceEvents: {
            type: Array,
        },
        minPhotos: {
            type: Number,
            required: true,
        },
        mainPhotoUrl: {
            type: String,
        },
        processingUploads: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        title() {
            if (this.servce) {
                return `Analytics for ${this.service.firstName + ' ' + this.service.lastName}`;
            }
            return 'Analytics';
        },
        pdfTitle() {
            if (this.service) {
                return `Viewers for ${this.service.firstName + ' ' + this.service.lastName}`;
            }
            return 'Viewers';
        },
        photoCount() {
            return this.$store.state.tributeVideo.photos.length;
        },
    },
    watch: {
        // tributeDeadline() {
        //     if (this.tributeDeadline) {
        //         this.setTributeDeadline = true;
        //     } else {
        //         this.setTributeDeadline = false;
        //     }
        // },
        stepIndex() {
            if (this.stepIndex == 1) {
                if (this.tributeVideo.deadline != null) {
                    this.tributeDeadline = this.tributeVideo.deadline;
                } else {
                    this.setDefaultDeadline();
                }
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoPhotos',
            'updateTributeVideoSelectedPhotos',
        ]),

        skipMainPhotoUpload(eventId) {
            if (!eventId || eventId <= 0) {
                this.showSnackbar({ message: 'Invalid event Id', color: 'error' });
                return;
            }

            if (this.tributeVideo.deeplink != null) {
                this.stepIndex = 3;
                return;
            }

            var data = {
                deadline: null,
                eventId: eventId,
            };

            if (this.setTributeDeadline && this.tributeDeadline != null) {
                let time = new Date(this.tributeDeadline);
                let offset = time.getTimezoneOffset() / 60;
                time.setHours(time.getHours() - offset);
                data.deadline = time;
            }

            this.loading = true;
            this.axiosInstance
                .post(`TributeVideo`, data)
                .then(resp => {
                    const tributeVideo = {
                        ...resp.data,
                        firstName: this.service.firstName,
                        lastName: this.service.lastName,
                        birthDate: this.service.birthDate,
                        deathDate: this.service.deathDate,
                    };
                    this.updateTributeVideo(tributeVideo);
                    this.$emit('refresh-share-tab');
                    this.stepIndex = 3;
                })
                .catch(err => {
                    console.log(err, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleUploadCancelled(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].reset();
            }
        },
        clearDeadline() {
            this.setTributeDeadline = false;
            this.tributeDeadline = '';

            this.stepIndex = this.tributeVideo.mainPhotoUrl ? 3 : 2;

            this.axiosInstance
                .post(`TributeVideo/settings/${this.tributeVideo.id}`, {
                    deadline: null,
                })
                .then(resp => {
                    this.$emit('refresh-deadline', this.tributeDeadline);
                    this.updateTributeVideo({ deadline: this.tributeDeadline });

                    this.showSnackbar({ message: 'Upload deadline updated' });
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        handleDeadlineSave() {
            // If deeplink, tribute video db record already exists.
            if (this.tributeVideo.deeplink) {
                //Update deadline

                if (this.tributeDeadline) {
                    this.submitTributeDeadline(this.tributeVideo.id);
                    this.stepIndex = 3;
                }
            } else {
                //Save deadline to use when creating tribute video db record
                this.handleInlineSave();

                if (this.tributeVideo.mainPhotoUrl) {
                    this.stepIndex = 3;
                } else {
                    this.stepIndex = 2;
                }
            }
        },
        skipDeadline() {
            this.setTributeDeadline = false;
            this.tributeDeadline = '';

            if (this.tributeVideo.mainPhotoUrl || this.tributeVideo.deeplink) {
                this.stepIndex = 3;
            } else {
                this.stepIndex = 2;
            }
        },
        setDefaultDeadline() {
            const scheduledEvents = this.serviceEvents.filter(x => x.time != null && x.time != '0001-01-01T00:00:00');

            scheduledEvents.sort((a, b) => new Date(a.time) - new Date(b.time));

            let defaultDate = new Date();
            defaultDate.setDate(defaultDate.getDate() + 6);
            defaultDate.setHours(17);
            defaultDate.setMinutes(0);
            defaultDate.setSeconds(0);

            this.tributeDeadline = defaultDate.toISOString(true);

            if (scheduledEvents.length > 0) {
                var firstEvent = scheduledEvents[0];

                var eventDate = new Date(firstEvent.time);
                var currentDate = new Date();

                if (eventDate > currentDate) {
                    var currentHour = eventDate.getHours();
                    let offset = eventDate.getTimezoneOffset() / 60;
                    eventDate.setHours(currentHour - (1 + offset));
                    this.tributeDeadline = eventDate.toISOString(true);
                }
            }
        },
        initOnboard() {
            this.windowIndex = 1;

            this.setDefaultDeadline();
        },
        handleSetDeadlineDate() {
            if (this.$refs.deadlineCalendar) {
                this.$refs.deadlineCalendar.handleSetNextTimeButton();
            }
        },
        handleInlineSave() {
            this.setTributeDeadline = true;
            this.stepIndex = 2;
        },
        handleStepClick(val) {
            if (val == 3) {
                this.skipMainPhotoUpload(this.event.id);
            }
            this.stepIndex = val;
        },
        submitTributeDeadline(id) {
            // If there is an event time passed in, just check if it's older than 30 days.
            let time = new Date(this.tributeDeadline);

            if (this.$refs.deadlineCalendar.renderedDate == '') {
                // time = new Date('0001-01-01T00:00:00');
                time = null;
            } else {
                let offset = time.getTimezoneOffset() / 60;
                time.setHours(time.getHours() - offset);
            }

            this.axiosInstance
                .post(`TributeVideo/settings/${id}`, {
                    deadline: time,
                })
                .then(resp => {
                    this.$emit('refresh-deadline', this.tributeDeadline);
                    this.updateTributeVideo({ deadline: this.tributeDeadline });
                    this.showSnackbar({ message: 'Upload deadline updated' });
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        handleUppyOpen(ref = null) {
            if (ref != null) {
                this.$refs[ref].openFileSelection();
            } else {
                this.$refs.onboardingUploader.openFileSelection();
            }
        },
        handleUploadSuccess(remaining) {
            this.$emit('upload-success', remaining);
        },
        handleUploadProgress(progress) {
            this.$emit('upload-progress', progress);
        },
        handleMainImgSrc(url) {
            //TODO: handle local image support with imgKit smart crop
            if (this.confirmationPhotoUrl) {
                return url;
            }

            if (url) {
                const path = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-500,h-500,fo-face/' + path;
            }
        },
        async handleConfirmMainFinished() {
            const tribute = await this.getTributeVideo(this.event.id);
            if (tribute && this.setTributeDeadline && this.tributeDeadline != null) {
                this.submitTributeDeadline(tribute.id);
            }

            this.updateTributeVideo({ ...tribute });

            this.loading = false;
            this.stepIndex = 3;
            this.chooseAnotherMainPhoto();
            this.$emit('refresh-photos');
            this.$emit('refresh-share-tab');
        },
        getTributeVideo(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideo/get-tribute-by-event/${id}`)
                .then(response => {
                    this.updateTributeVideo({ ...response.data });
                    return response.data;
                })
                .catch(error => {
                    console.log(error, 'error');
                });
        },
        initShareModal() {
            const shareModal = this.$refs.shareModal;

            if (shareModal && typeof shareModal.open == 'function') {
                shareModal.open();
            }
        },
        handleInvalidEmail(email) {
            this.showSnackbar({ message: `${email} is not a valid email`, color: 'error' });
        },
        handleInviteSubmit(contacts) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: contacts.url,
            };

            this.$refs.shareModal.reset();
            this.uploadModal = false;
            this.axiosInstance
                .post(`/TributeVideoPhoto/tribute-invite/${this.tributeVideo.id}`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Invitations sent' });
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401) {
                        this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                    }
                });
        },

        manualBlur() {
            this.$refs.emailCombo.blur();
        },
        handleMainPhotoConfirm() {
            this.loading = true;

            var replaceMain = this.tributeVideo.mainPhotoId >= 0;
            this.$refs.mainPhotoUploader.initDragDropUpload(replaceMain);
        },
        chooseAnotherMainPhoto() {
            this.confirmingPhoto = false;
            this.$refs.mainPhotoUploader.removeFile(this.$refs.mainPhotoUploader.tempFiles[0]);
        },
        promptMainPhotoConfirm(photo) {
            if (photo?.data) {
                this.confirmationPhotoUrl = this.getFileURL(photo);
                this.confirmingPhoto = true;
            }
        },
        getFileURL(file) {
            if (file?.data) {
                return URL.createObjectURL(file.data);
            }
            return '';
        },
        // downloadPdf() {
        //     this.$refs.qrPrint.$refs.html2Pdf.generatePdf();
        // },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(async result => {
            // Get Api Token
            this.token = result.__raw;
            this.createAxiosInstance();
        });
        if (this.tributeVideo.mainPhotoId || this.tributeVideo.deeplink) {
            this.windowIndex = 1;
            this.stepIndex = 3;

            if (this.tributeVideoDeadline) {
                this.tributeDeadline = this.tributeVideo.deadline;
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.active-toggle-btn.family-sel {
    color: white !important;
    border: none !important;
    background-color: #ab0658 !important;
}
.active-toggle-btn.general-sel {
    color: white !important;
    border: none !important;
    background-color: #0d3d60 !important;
}

//     .calendar-wrapper {
//         display: flex;
//         justify-content: center;
//         width: 100%;

//         .ms-date-picker {
//             width: fit-content;
//         }
//         .main-container {
//             position: relative;
//             top: 0;
//             margin-bottom: 18px;
//         }
//         .v-input {
//             display: none !important;
//         }
//     }

@container stepper (max-width: 280px) {
    .stepper-content {
        // min-width: 280px !important;
        .step-label {
            display: none;
        }
    }
}
</style>
